import React from 'react';
import ReactDOM from 'react-dom';
import { ajaxMenuConfig, initApplication } from 'app/blocks/ajax/config/ajax-config';
import { injectDTM } from 'app/blocks/analytics/analytics';
import AuthProvider from 'app/blocks/common/providers/AuthProvider';
import { renderMenu } from 'app/blocks/menu/__renderer/menu__renderer';
import { isMock } from 'app/blocks/middleware/config';
import PageFooter from 'app/blocks/page/__footer/page__footer';
import StaticSmoothNavigation from 'app/blocks/StaticSmoothNavigation';
import injectCRM from 'app/blocks/SupportWidget/external';

import './app/styles/utils/text-utils.scss';
import './app/styles/utils/spacing.scss';
import './app/styles/utils/utils.scss';
import './app/styles/baseline.scss';
import './app/styles/global.scss';
import './app/styles/footer.scss';
import './app/ui/form/form.scss'; // for combobox search input

ajaxMenuConfig();

async function run() {
    ReactDOM.render(
        <AuthProvider>
            {renderMenu()}
            <PageFooter />
        </AuthProvider>,
        document.getElementById('AS__footer'),
    );

    await initApplication();

    if (!isMock()) {
        if (!window._satellite) {
            injectDTM();
        }

        injectCRM();
    }

    new StaticSmoothNavigation(window.history, window.location).run();
}

run();
