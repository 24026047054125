/* eslint-disable @typescript-eslint/no-shadow,@typescript-eslint/no-use-before-define */
// eslint-disable-next-line max-classes-per-file
import update from 'immutability-helper';
import $ from 'jquery';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import semverCoerce from 'semver/functions/coerce';
import semverSatisfies from 'semver/functions/satisfies';
import _ from 'underscore';
import velocity from 'velocityjs';

function createPickField(source, fieldPrefix, idField, nameField) {
    return {
        id: source[fieldPrefix + (idField || 'Id')],
        name: source[fieldPrefix + (nameField || 'Name')],
    };
}

function readPickField(source, fieldPrefix, idField, nameField) {
    let id;
    let name;

    if (source === undefined || source === null) {
        id = source;
        name = source;
    } else {
        id = source.id;
        name = source.name;
    }

    return {
        [fieldPrefix + (idField || 'Id')]: id,
        [fieldPrefix + (nameField || 'Name')]: name,
    };
}

function updateProperty(object, property, value) {
    const holder = {};
    holder[property] = { $set: value };
    return update(object, holder);
}

function updateProperties(object, properties) {
    const holder = {};
    _.each(properties, (value, property) => {
        holder[property] = { $set: value };
    });
    return update(object, holder);
}

function supportCreditCardPayment(browser) {
    if (browser.name === 'safari') {
        return !semverSatisfies(semverCoerce(browser.version), '12.x || 13.x || 14.0.x');
    }

    if (browser.name === 'ios') {
        return !semverSatisfies(semverCoerce(browser.version), '12.x');
    }

    return true;
}

function isNumeric(n) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

const validation = {
    VALIDATION_CNST: {
        CODE_FIELD_EMPTY: 'CODE_FIELD_EMPTY',
        CODE_FIELD_INVALID: 'CODE_FIELD_INVALID',
    },
    isAllKeysFalseOrEmpty(obj) {
        return _.values(obj).every(
            propValue =>
                (typeof propValue !== 'boolean' && _.isEmpty(propValue)) ||
                propValue === false ||
                propValue == null ||
                propValue === '',
        );
    },

    asFalseIfAllObjPropsEmpty(obj) {
        return !this.isAllKeysFalseOrEmpty(obj) && obj;
    },
    validationResult(code, message) {
        return {
            code,
            message,
        };
    },
    failResult(code, message) {
        return this.validationResult(code, message);
    },
    emptyField(message) {
        return this.failResult(this.VALIDATION_CNST.CODE_FIELD_EMPTY, message);
    },
    validateRequiredField(item, message) {
        return _.isEmpty(item) && this.emptyField(message);
    },
    validateRequiredPickItem(item, msg) {
        return !(item && (item.name || item.id)) ? this.emptyField(msg) : null;
    },
    isPhoneNumberValid(phoneNumberValue) {
        return /^(?!0+$)\d{7,13}$/.test(phoneNumberValue);
    },
    validateCharacters(value) {
        return /^[a-zA-Z0-9\xc0-\xfd.,'\s-]*$/.test(value);
    },
    validateVatIdNumber(value, pattern) {
        let regexValue: string | RegExp = '';

        if (pattern !== undefined) {
            // decoding base64 encoded regex
            regexValue = window.atob(pattern);
        } else {
            regexValue = /^[a-zA-Z0-9]*$/;
        }

        const regex = new RegExp(regexValue);
        return regex.test(value);
    },
    isEmailValid(emailIdVal) {
        // eslint-disable-next-line no-use-before-define
        return isEmailValid(emailIdVal);
    },
    isPasswordValid(passwordValue) {
        const specialsASCII = '\u{21}-\u{2F}\u{3A}-\u{40}\u{5B}-\u{60}\u{7B}-\u{7E}';
        const charsCategories = ['a-z', 'A-Z', '0-9', specialsASCII];
        const lengthMinimum = 10;
        const lengthMaximum = 32;
        const categoriesMinimum = 2;

        const allowed = new RegExp(`^[${charsCategories.join('')}]{${lengthMinimum},${lengthMaximum}}$`);
        if (!passwordValue || !allowed.test(passwordValue)) return false;

        const checkCategories = charsCategories
            .map(chars => new RegExp(`[${chars}]`).test(passwordValue))
            .filter(el => el);
        return checkCategories.length >= categoriesMinimum;
    },
    /**
     * based on
     * https://en.wikipedia.org/wiki/Basic_Latin_(Unicode_block)
     * https://en.wikipedia.org/wiki/Latin-1_Supplement_(Unicode_block)
     * https://en.wikipedia.org/wiki/Latin_Extended-A
     *
     * @param value
     * @returns {boolean}
     */
    validateInputFullLatinExtendedChars(value) {
        return /^[\u{20}-\u{7E}\u{A0}-\u{17F}]*$/u.test(value);
    },

    /**
     * Almost like validateInputFullLatinExtendedChars but without several special chars
     * @param value
     * @returns {boolean}
     */
    validateInputConstrainedLatinExtendedChars(value) {
        return /^[A-Za-z\u{C0}-\u{17F}0-9.;,'\s-]*$/u.test(value);
    },

    validatePostalCode(value, pattern) {
        if (pattern !== undefined) {
            const regex = new RegExp(pattern);
            return regex.test(value);
        }
        return true;
    },

    hasAnySymbol(value) {
        // eslint-disable-next-line no-control-regex
        return !/^[\s\u{00}-\u{2F}\u{3A}-\u{40}\u{5B}-\u{60}\u{7B}-\u{BF}\u{D7}\u{F7}]*$/iu.test(value);
    },

    notEmpty(value) {
        if (typeof value === 'boolean') {
            return true;
        }
        if (typeof value === 'function') {
            return true;
        }
        if (value instanceof Date) {
            return true;
        }
        return isNumeric(value) ? true : !isEmpty(value);
    },

    validate: <T>(value: T, ar: Array<[(v?: T) => boolean, string]> = []): string => {
        let error = '';

        ar.some(([validator, message]) => {
            if (validator(value)) {
                return false;
            }

            error = message;
            return true;
        });

        return error;
    },
};

// Toggle Panels for online open and openaccess
function togglePanel(currentTarget) {
    $(currentTarget).next('.show-content').slideToggle('slow');
    $(currentTarget).find('.re-place').toggleClass('arrow-up');
    $(currentTarget).find('.re-place').toggleClass('arrow-down');
}

// Checks for email id pattern validity
function isEmailValid(email: string): boolean {
    let valid = true;
    try {
        let user = null;
        let domain = null;

        // AS-4408 forbid non-ASCII symbols
        // eslint-disable-next-line no-control-regex
        if (
            email.match(
                '^([\\w-+]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,}(?:\\.[a-z]{2})?)$',
            ) === null
        )
            throw new Error();

        if (email.startsWith(' ') || email.endsWith(' ') || email.includes('\n')) {
            throw new Error();
        }

        let charUName = false;
        if (email.startsWith('"')) {
            user = email.substr(0, email.lastIndexOf('"') + 1);
            domain = email.substr(email.lastIndexOf('"') + 1);

            if (!domain.startsWith('@')) throw new Error();
            domain = domain.substr(1);
            user = user.substr(1, user.length - 2);

            charUName = true;
        } else {
            const emailParts = email.split('@');

            if (
                emailParts.length !== 2 ||
                (emailParts[0] && !emailParts[0].length) ||
                (emailParts[1] && !emailParts[1].length)
            ) {
                throw new Error();
            }

            [user, domain] = emailParts;
        }

        if (domain.match(/\.\./g) !== null) throw new Error();
        if (domain.match(/@/g)) throw new Error();
        if (domain.length < 1) throw new Error();
        // eslint-disable-next-line
        if (domain.match(/[! "'@#$%^&*()+=\{\}\[\]\\\/,`~<>\?]/g)) {
            if (!domain.match(/^\[IPv6:[:0-9a-f]*\]/g)) throw new Error();
        }

        if (!charUName && user.match(/[" ]/g) !== null) throw new Error();
        if (!charUName && user.match(/[" ]/g) !== null) throw new Error();
    } catch (error) {
        valid = false;
    }
    return valid;
}

function isPhoneNumberValid(phoneNumberValue) {
    return /^\d*$/.test(phoneNumberValue);
}

function convertDateToMilliSeconds(date) {
    return new Date(date).getTime();
}

function getQueryParam(inputURL: string, decodeURI: boolean = false): Record<string, string> {
    const queryResult = {};

    if (inputURL.includes('?')) {
        inputURL
            .split('?')[1]
            .split('&')
            .forEach(param => {
                queryResult[param.split('=')[0]] = decodeURI
                    ? decodeURIComponent(param.split('=')[1])
                    : param.split('=')[1];
            });
    }

    return queryResult;
}

function queryStringIsNotEmpty(inputURL) {
    return inputURL.split('?')[1] !== undefined;
}

function getQueryParamSafe(inputURL, ...args) {
    try {
        return inputURL && queryStringIsNotEmpty(inputURL) ? getQueryParam(inputURL, ...args) : {};
    } catch (error) {
        return {};
    }
}

async function scorePassword(pass, userDataArr) {
    const zxcvbn = (await import('zxcvbn/dist/zxcvbn')).default;
    const additionalDict = ['wiley'].concat(userDataArr);

    return zxcvbn(pass, additionalDict);
}

async function checkPassStrength(passwordValue, userDataArr = []) {
    const STRENGTH = [
        { score: 0, text: 'Worst', color: 'Red' },
        { score: 1, text: 'Bad', color: 'Red' },
        { score: 2, text: 'Weak', color: 'Crimson' },
        { score: 3, text: 'Good', color: 'DarkOrange' },
        { score: 4, text: 'Strong', color: 'MediumSeaGreen' },
    ];

    const { score } = await scorePassword(passwordValue, userDataArr);
    return STRENGTH.find(el => el.score === score);
}

const numbers = {
    formatNumber(number) {
        if (number === undefined) {
            return number;
        }
        const absNumber = Math.abs(number);
        const integer = parseInt(String(absNumber), 10);
        let fractional: string | number = Math.round((absNumber % 1) * 100);
        if (fractional < 10) {
            fractional = `0${fractional}`;
        }
        return `${(number < 0 ? '-' : '') + integer}.${fractional}`;
    },
    formatPercents(percents) {
        if (Number.isInteger(percents)) {
            return `${String(percents)}%`;
        }

        return `${percents.toFixed(2).replace(/\.00$/, '')}%`;
    },
};

const network = {
    getErrorFromFetchResponse(response) {
        return (response && response.responseJSON && response.responseJSON.error) || {};
    },
};

const formatDate = {
    fromUTCtoFormatDate(utc) {
        return moment(utc).format('YYYY-MM-DD');
    },
    fromFormatDateToUTC(date) {
        return moment(date).utc().valueOf();
    },
};

const DATE_PAIR_ERROR_CODES = {
    INVALID_FROM_DATE: 'INVALID_FROM_DATE',
    INVALID_FROM_DATE_GREATER_TO_DATE: 'INVALID_FROM_DATE_GREATER_TO_DATE',
    INVALID_FROM_DATE_FUTURE: 'INVALID_FROM_DATE_FUTURE',
    INVALID_TO_DATE: 'INVALID_TO_DATE',
    EQUAL_DATES: 'EQUAL_DATES',
};

function scrollToTop() {
    window.scrollTo(0, 0);
}

function scrollToErrorField(name) {
    const $e = name
        ? $(`[data-seleniumid="${name}-toggle"]`).parent().find('.error:not(.hidden)')
        : $('.error:not(.hidden)');
    if ($e && $e.length) {
        window.scrollTo(0, $e.eq(0).offset().top - 75);
    }
}

function scrollToElement(element, delay = 300, offset = 0) {
    setTimeout(() => {
        const $element = element.get ? element : $(element);
        if ($element.get(0) != null && $element.offset() != null) {
            $('html, body').animate(
                {
                    scrollTop: $element.offset().top + 5 + offset,
                },
                'fast',
            );
        }
    }, delay);
}

function setStateAsPromise(newProps, handler): Promise<void> {
    return new Promise(resolve => {
        this.setState(newProps, () => {
            if (handler) {
                handler();
            }
            resolve();
        });
    });
}

const HUMAN_FRIENDLY_FORMAT = 'D MMMM, YYYY'; // fixme: review usages and confirm if it should be "LL" to international support
const ISO_FORMAT = 'YYYY-MM-DD';
const MINIMAL_DATE = new Date('1900-01-01');

function date(date?: moment.MomentInput, defaultValue?: string) {
    if (!date) {
        return defaultValue || '';
    }
    return moment(date).format(HUMAN_FRIENDLY_FORMAT);
}

function isoFormattedDateString(date, defaultValue) {
    if (!date) {
        return defaultValue || '';
    }
    return moment(date).format(ISO_FORMAT);
}

function isValidDateString(dateString) {
    return moment(dateString, HUMAN_FRIENDLY_FORMAT, true).isValid() || moment(dateString, ISO_FORMAT, true).isValid();
}

function isValidDate(date) {
    return (
        date !== null &&
        // eslint-disable-next-line no-restricted-globals
        ((!isNaN(date) || !_.isEmpty(date)) && typeof date === 'string'
            ? isValidDateString(date)
            : !Number.isNaN(new Date(date).getTime()))
    );
}

function validateAndGetDateFormattedValue(date, defaultValue, format) {
    return isValidDate(date) ? moment(date).format(format) : defaultValue;
}

function validateAndGetDateObject(date, defaultValue) {
    return isValidDate(date) ? moment(date).toDate() : defaultValue;
}

function validateAndGetDateMs(date, defaultValue) {
    return isValidDate(date) ? new Date(date).getTime() : defaultValue;
}

function strToDate(str, format = HUMAN_FRIENDLY_FORMAT) {
    return moment(str, format).toDate();
}

function validateDatePair(fromDateString, toDateString) {
    const isEmptyFromDate = fromDateString === '';
    const isEmptyToDate = toDateString === '';
    const errorList = [];
    let isDatesValid = true;
    let isDatesFormatValid = true;

    if (!isEmptyFromDate || !isEmptyToDate) {
        const fromDate = strToDate(fromDateString);
        const toDate = strToDate(toDateString);

        if (!isEmptyFromDate) {
            if (!isValidDateString(fromDateString)) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE);
                isDatesFormatValid = false;
            } else if (fromDate < MINIMAL_DATE) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE);
                isDatesValid = false;
            } else if (fromDate > new Date()) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE_FUTURE);
                isDatesValid = false;
            }
        }

        if (!isEmptyToDate) {
            if (!isValidDateString(toDateString)) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_TO_DATE);
                isDatesFormatValid = false;
            } else if (toDate < MINIMAL_DATE) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_TO_DATE);
                isDatesValid = false;
            }

            if (isEmptyFromDate) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE);
                isDatesValid = false;
            }
        }

        if (isDatesFormatValid) {
            if (!isEmptyToDate && fromDate > toDate) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE_GREATER_TO_DATE);
                isDatesValid = false;
            } else if (!isEmptyToDate && fromDate.getTime() === toDate.getTime()) {
                errorList.push(DATE_PAIR_ERROR_CODES.EQUAL_DATES);
                isDatesValid = false;
            }
        } else {
            isDatesValid = false;
        }
    }

    return { errorList, isDatesValid, isDatesFormatValid };
}

function scrollIntoDomElement(selector = 'body', param = true, delay = 0) {
    return setTimeout(() => {
        try {
            document.querySelector(selector).scrollIntoView(param);
        } catch (error) {
            console.warn(`Exception for '${selector}' selector`);
            console.warn(error);
        }
    }, delay);
}

function scrollToFirstError(container, focus) {
    const $container = $(container);
    const $firstError = $container.find('.error:visible:first').get(0) || $container.get(0);
    if ($firstError) {
        $firstError.scrollIntoView?.(true);
        window.scrollBy(0, -50);
        if (focus) $firstError.focus();
    }
}

function scrollToElementBySelector(selector, focus = false) {
    const element = document.querySelector(selector);
    if (element) {
        element.scrollIntoView?.(true);
        window.scrollBy(0, -50);
        if (focus) element.focus();
    }
}

function getUncompletedPanels(panels) {
    panels.forEach(panel => panel.updateTopPosition && panel.updateTopPosition());
    return _.filter(panels, panel => !panel.isComplete()).sort((a, b) => {
        if (a.topPosition < b.topPosition) {
            return -1;
        }
        if (a.topPosition > b.topPosition) {
            return 1;
        }
        return 0;
    });
}

function stripHtml(string) {
    return string.replace(/(<([^>]+)>)/gi, '');
}

function replaceLineBreakSymbolsToBrTag(string) {
    return string.replace(/(\r\n|\n|\r)/gm, '<br />');
}

function setLinksToOpenInNewWindow(string) {
    return string.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ');
}

function isArticlePublished(data) {
    const inSearchResp = data?.publishedAsEarlyViewDate || data?.publishedInIssueDate;
    const inArticleAndJournalResp = data?.pubStatusDates?.EARLY_VIEW || data?.pubStatusDates?.ISSUE_PUB_ONLINE;
    return !!(inSearchResp || inArticleAndJournalResp || data?.published); // todo: check if 'data.published' is legacy
}

/**
 * Clones object
 * doesn't resolve dates correctly and fails on circular structures
 */
function cloneDeepSimple(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function plainValueDiff(object1, object2) {
    const diff = [];
    let value1;
    let value2;

    if (!_.isEmpty(object1)) {
        _.each(object1, (item, key) => {
            value1 = item;
            value2 = (object2 && object2[key]) || '';
            if (value1 !== value2) {
                diff.push({ key, value1, value2 });
            }
        });
    }

    if (!_.isEmpty(object2)) {
        _.each(object2, (item, key) => {
            value1 = (object1 && object1[key]) || '';
            value2 = item;
            if (value1 !== value2 && !_.findWhere(diff, { key })) {
                diff.push({ key, value1, value2 });
            }
        });
    }

    return diff;
}

function looseEqual(v1, v2) {
    if (v1 === v2) return true;
    const toNullValue = val => {
        if (Array.isArray(val) && val.length === 0) return null;
        if (val instanceof Object && Object.keys(val).length === 0) return null;
        if (val === undefined) return null;
        return val;
    };
    const value1 = toNullValue(v1);
    const value2 = toNullValue(v2);
    const isSimple1 = !(value1 instanceof Object) && !(value1 instanceof Function);
    const isSimple2 = !(value2 instanceof Object) && !(value2 instanceof Function);
    if (isSimple1 && isSimple2) {
        // we need == instead of ===
        // eslint-disable-next-line eqeqeq
        if (value1 == value2 && (!!value1 || !!value2)) return true;
        if (value1 === null && !value2) return true;
        if (value2 === null && !value1) return true;
    }

    const v1IsObject = !isSimple1 && value1 instanceof Object && !Array.isArray(value1);
    const v2IsObject = !isSimple2 && value2 instanceof Object && !Array.isArray(value2);

    if (v1IsObject || v2IsObject) {
        const obj1 = value1 || {};
        const obj2 = value2 || {};
        const keys = _.union(Object.keys(obj1), Object.keys(obj2));
        return keys.length === 0 || keys.findIndex(key => !looseEqual(obj1[key], obj2[key])) < 0;
    }
    return _.isEqual(value1, value2);
}

// workaround for browsers that doesn't work correctly with placeholders (e.g. Safari 10.0.3)
// this function is used to remove placeholder if value is set and show when value is empty
const removePlaceholderOnEmptyValue = (value, placeholderText) => (value ? '' : placeholderText);

function template(target, params = {}) {
    let message = target;

    try {
        if (target.includes('<%')) {
            message = _.template(target)(params);
        } else if (target.includes('$') || target.includes('#')) {
            // @ts-ignore
            message = velocity.render(target, params, undefined, { escape: false });
        }
    } catch (error) {
        console.log(error);
    }

    return message;
}

function windowLocationReplace(url) {
    window.location.replace(decodeURIComponent(url));
}

function trimStringProperties(obj) {
    const out = { ...obj };

    Object.entries(out).forEach(([key, value]) => {
        if (typeof value === 'string') {
            out[key] = value.trim();
        }
    });

    return out;
}

const getDisplayName = component => component.displayName || component.name || 'Component';

// https://github.com/reactjs/reactjs.org/issues/16
const normalizeInputValue = val => (val === null || val === undefined ? '' : val);

const getSortArrFromObject = obj =>
    Object.keys(obj || {})
        .map(fieldName => ({
            _id: fieldName,
            ...obj[fieldName],
            order: Number(obj[fieldName].order) || 0,
        }))
        .sort((a, b) => a.order - b.order);

const getFocusableChildren = parentElement => {
    const elements = parentElement.querySelectorAll(`
            a[href]:not([tabindex="-1"]),
            area[href]:not([tabindex="-1"]),
            input:not([disabled]):not([tabindex="-1"]),
            select:not([disabled]):not([tabindex="-1"]),
            textarea:not([disabled]):not([tabindex="-1"]),
            button:not([disabled]):not([tabindex="-1"]),
            iframe:not([tabindex="-1"]),
            [tabindex]:not([tabindex="-1"]),
            [contentEditable=true]:not([tabindex="-1"])
        `);
    return [...elements];
};

function cleanToken(token) {
    return decodeURIComponent(token).trim();
}

function includesEachWord(str = '', search = '') {
    const strLC = str.toLowerCase();
    const words = search
        .toLowerCase()
        .split(/\s/)
        .filter(w => !!w);

    return words.map(w => strLC.includes(w)).every(i => i);
}

function cleanFields(component, cleaner, postCleaner = s => (s.trim ? s.trim() : s)) {
    return new Promise(resolve => {
        let obj = {};
        component.setState(
            state => {
                obj = cleaner(state);
                Object.keys(obj).forEach(key => {
                    obj[key] = obj[key] ? postCleaner(obj[key]) : obj[key];
                });
                return obj;
            },
            () => resolve(obj),
        );
    });
}

function isOnlineOpenJournal(journal) {
    return journal.revenueModel === 'OO';
}

class Deferred {
    resolve;

    reject;

    promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
    });
}

function waitForCallback(condition, resolveCallback, rejectCallback, interval = 30, timeout = 1000) {
    const startTime = Date.now();

    function recursiveCallBack() {
        const workingTime = Date.now() - startTime;
        if (condition && condition()) {
            resolveCallback();
        } else if (workingTime <= timeout) {
            setTimeout(recursiveCallBack, interval);
        } else {
            rejectCallback(new Error(`time-out after ${workingTime}ms on condition: ${condition}`));
        }
    }

    recursiveCallBack();
}

function makePromiseForWait(condition, interval = 100, timeout = 10000) {
    return new Promise((resolve, reject) => {
        waitForCallback(condition, resolve, reject, interval, timeout);
    });
}

function strToBool(str) {
    return str === 'true';
}

// cb(0), cb(100) will be raised in any case
// todo: other logic (including backend 202 and long operations with re-checking)
// eslint-disable-next-line default-param-last
async function progressiveRequest<T>(
    request: () => Promise<T> | T,
    configuration: {
        repeat?: boolean;
        repeatOnErrors?: string[];
        delay?: number;
        delayIncrement?: number;
        maxDelay?: number;
        maxRequestTime?: number;
    } = {},
    cbProgress: (percent: number) => void = () => {},
): Promise<T> {
    const config = {
        repeat: false,
        repeatOnErrors: null,
        delay: 2000,
        delayIncrement: 2000,
        maxDelay: 30000,

        maxRequestTime: 60 * 60 * 1000,
        ...configuration,
    };

    const endTime = performance.now() + config.maxRequestTime;
    const timer = async time => new Promise(resolve => setTimeout(resolve, time));

    let delayBetweenAttempts = config.delay;
    let result = null;
    let resultError = null;

    cbProgress(0);

    let percent = 1;
    const fakeInterval = setInterval(() => {
        percent += (100 - percent) / (30 + percent);
        cbProgress(Math.floor(percent));
    }, 1000);

    if (config.repeat) {
        while (true) {
            try {
                resultError = null;
                // eslint-disable-next-line no-await-in-loop
                result = await request();
                break;
            } catch (error) {
                resultError = error;

                // cbProgress(Math.round((Math.round(performance.now()) / (endTime / 2)) * 100));

                if (performance.now() < endTime) {
                    if (!config.repeatOnErrors || config.repeatOnErrors.includes(error.code)) {
                        console.error(`request is not available on attempt, delay ${delayBetweenAttempts}`);
                        // eslint-disable-next-line no-await-in-loop
                        await timer(delayBetweenAttempts);

                        if (delayBetweenAttempts < config.maxDelay) {
                            delayBetweenAttempts += config.delayIncrement;
                        }
                    } else break;
                } else break;
            }
        }
    } else {
        try {
            result = await request();
        } catch (error) {
            resultError = error;
        }
    }

    clearInterval(fakeInterval);

    cbProgress(100);

    if (resultError) {
        throw resultError;
    }

    return result;
}

function keymirror<K extends string, T extends Record<K, any>>(obj: T): { [K in keyof T]: K } {
    Object.keys(obj).forEach(key => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = key;
    });

    return obj;
}

const compose = (...hocs) =>
    hocs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args)),
        arg => arg,
    );

const compactObject = obj => (!!obj && Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v))) || obj;

class Handlers {
    link(object, method) {
        if (method === 'link') {
            return undefined;
        }
        this[method] = object[method].bind(object);
        return this[method];
    }
}

export {
    Handlers,
    checkPassStrength,
    cleanToken,
    cloneDeepSimple,
    convertDateToMilliSeconds,
    compactObject,
    compose,
    createPickField,
    date,
    DATE_PAIR_ERROR_CODES,
    Deferred,
    formatDate,
    getDisplayName,
    getFocusableChildren,
    getQueryParam,
    getQueryParamSafe,
    getSortArrFromObject,
    getUncompletedPanels,
    HUMAN_FRIENDLY_FORMAT,
    isArticlePublished,
    isEmailValid,
    ISO_FORMAT,
    isOnlineOpenJournal,
    isPhoneNumberValid,
    isValidDate,
    isValidDateString,
    keymirror,
    looseEqual,
    makePromiseForWait,
    network,
    normalizeInputValue,
    numbers,
    plainValueDiff,
    queryStringIsNotEmpty,
    readPickField,
    removePlaceholderOnEmptyValue,
    replaceLineBreakSymbolsToBrTag,
    scorePassword,
    scrollToErrorField,
    scrollToFirstError,
    scrollToTop,
    scrollToElement,
    scrollToElementBySelector,
    setLinksToOpenInNewWindow,
    setStateAsPromise,
    stripHtml,
    strToBool,
    strToDate,
    supportCreditCardPayment,
    template,
    togglePanel,
    trimStringProperties,
    updateProperties,
    updateProperty,
    validateDatePair,
    validation,
    waitForCallback,
    windowLocationReplace,
    MINIMAL_DATE,
    validateAndGetDateObject,
    validateAndGetDateFormattedValue,
    validateAndGetDateMs,
    scrollIntoDomElement,
    includesEachWord,
    cleanFields,
    progressiveRequest,
    isoFormattedDateString,
};
