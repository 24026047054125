import get from 'lodash.get';
import moment from 'moment';
import { pageEvents, processSignLicenseClick } from 'app/blocks/analytics/analytics';
import { lAsync } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { template } from 'app/blocks/common/utils';
import * as middlewareArticleView from 'app/blocks/middleware/article-view';
import * as middlewareLicense from 'app/blocks/middleware/license';
import * as middleware from 'app/blocks/middleware/middleware';
import { getRootUrl } from 'app/blocks/middleware/utils';
import { showOutdatedDashboardModal } from 'app/blocks/Modal/OutdatedDashboardModal';
import { confirmStaticContent } from 'app/blocks/Modal/utils';
import LicenseFlowEnum from 'app/pages/license-signing-process/LicenseFlowEnum';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import { navigate } from 'app/route/history';
import ModalArticleShare from '../ModalArticleShare/ModalArticleShare';

type ActionButton = {
    additionalInfo?: any;
    grouped?: boolean;
    code: string;
    event?: string;
    icon?: string;
    params?: {
        ARTICLE_ID?: string;
        [key: string]: any;
    };
    primary?: boolean;
    target?: string;
    title: string;
    titleOnProgress?: string;
    toggle?: string;
    url?: string;
    onClick?: (...args: any) => void;
};

function asDate(anyDate, format = 'LL'): string {
    let dt = moment.utc(anyDate);
    if (!dt.isValid()) {
        const tm = +anyDate;
        if (tm > 0) {
            dt = moment.utc(tm);
        }
    }
    return dt.format(format);
}

function asBool(value) {
    // FALSE will be: null, false, "", "false"
    return !!(value && `${value}`.toLowerCase() !== 'false');
}

const TEMPLATE_PARAMS = {
    asDate,
    asBool,
    moment, // fixme: why? try to remove
};

function parseText(str, params) {
    return template(str, { ...params, ...TEMPLATE_PARAMS, ROOT: getRootUrl() });
}

function getStatusDescr(codes, panelName: string, statusCode: string, params?: Record<string, any>): string {
    const description = get(codes, `${panelName}.statuses.${statusCode}.description`, statusCode);

    return parseText(description, params);
}

function getStatusShort(codes, panelName: string, statusCode: string): string {
    return get(codes, `${panelName}.statuses.${statusCode}.short`, statusCode);
}

function errorCatcher() {
    return function (fn) {
        return async function (...args) {
            try {
                await fn(...args);
            } catch (error) {
                if (error.code === 'POSSIBLE_OUTDATED_DATA') {
                    showOutdatedDashboardModal();
                } else {
                    showDialog.error(error);
                }
            }
        };
    };
}

async function openWindow(url) {
    const articleWindow = window.open(url, '_blank');
    if (!articleWindow) {
        const message = await lAsync('ERROR.POPUP_BLOCKED_MESSAGE_FOR_ARTICLE', { url });
        showDialog.info({ message });
    }
}

function filterButtons(buttons) {
    return {
        grouped: buttons.filter(x => x.grouped),
        single: buttons.filter(x => !x.grouped),
    };
}

async function confirmOrderCancellation(): Promise<boolean> {
    return confirmStaticContent('panel_actions__buttons__cancel_order');
}

async function onCancelOrder(articleId: string, action: string): Promise<void> {
    if (await confirmOrderCancellation()) {
        await middlewareLicense.startFlow(articleId, action);
    }
}

async function viewFullArticle(id: string, journal): Promise<void> {
    const details = await middlewareArticleView.getDetails(id);

    if (details.needTocConsent) {
        if (await confirmStaticContent('view_full_article', { journal })) {
            await middlewareArticleView.tocConsent(id);
            await openWindow(details.pdfUrl);
        }
    } else {
        await openWindow(details.pdfUrl);
    }
}

function viewShareableLink(doi: string): void {
    showDialog.modal(closeAnd => ({
        type: ModalArticleShare,
        onClose: closeAnd(),
        doi,
    }));
}

function getActionsButtons(
    codes,
    panelName,
    actions = [],
    params = {},
    additionalInfo = {},
): { grouped: ActionButton[]; single: ActionButton[] } {
    try {
        if (!params) {
            throw new Error('params is now defined');
        }

        const buttons = actions
            .map(action => {
                const code = get(codes, `${panelName}.actions.${action.code}`);

                if (code) {
                    return {
                        title: code.text || action.code,
                        url: parseText(code.url || '', params),
                        target: code.target || '',
                        icon: code.icon,
                        primary: action.required,
                        grouped: !!code.grouped,
                        event: code.event,
                        code: action.code,
                        params,
                        titleOnProgress: code.text_progress || 'Loading...',
                        toggle: code.toggle,
                        additionalInfo,
                    };
                }

                console.error(`actions is not defined ${panelName} ${action.code}`);
                return null;
            })
            .filter(x => x != null);

        return filterButtons(buttons);
    } catch (error) {
        console.error(`panel name: ${panelName}`, error);
        return { grouped: [], single: [] };
    }
}

type HandleClick = (
    e: MouseEvent,
    button: ActionButton,
    handler: (button: ActionButton) => void,
    getContext?: () => Record<string, any>,
) => void;

const sendAnalytics = data => processSignLicenseClick.bind(null, data, pageEvents.LICENSE_SIGNING_INITIATION);

const handleClick: HandleClick = errorCatcher()(async (e, button, handler, getContext) => {
    if (button.event) {
        e.preventDefault();

        const articleId = button?.params?.ARTICLE_ID;

        switch (button.event) {
            case LicenseFlowEnum.CREATE_OO_ORDER:
            case LicenseFlowEnum.CONFIRM_OO_ORDER:
            case LicenseFlowEnum.SUBMIT_OO_ORDER:
            case LicenseFlowEnum.EDIT_OO_ORDER:
            case LicenseFlowEnum.CREATE_OO_ORDER_VCH:
            case LicenseFlowEnum.COMPLETE_REQUIRED_STEPS_VCH:
            case LicenseFlowEnum.SIGN_LICENSE:
            case LicenseFlowEnum.EDIT_LICENSE:
            case LicenseFlowEnum.UPLOAD_LICENSE:
            case LicenseFlowEnum.DOWNLOAD_LICENSE:
                await middlewareLicense.startFlow(
                    articleId,
                    button.event,
                    undefined,
                    sendAnalytics(button.additionalInfo),
                );
                break;
            case LicenseFlowEnum.CHANGE_AFFILIATION_DATA:
                await middlewareLicense.startFlow(
                    articleId,
                    button.event,
                    StepEnum.FUNDERS,
                    sendAnalytics(button.additionalInfo),
                );
                break;
            case LicenseFlowEnum.CANCEL_OO_ORDER:
            case LicenseFlowEnum.CANCEL_OO_DRAFT:
                await onCancelOrder(articleId, button.event);
                break;
            case 'SEARCH_SAVE_TO_FAVORITES':
                await middleware.profile.makeFavorite(button.params.JOURNAL_ID);
                handler(button);
                break;
            case 'SEARCH_REMOVE_FROM__FAVORITES':
                await middleware.profile.removeFromFavorites(button.params.JOURNAL_ID);
                handler(button);
                break;
            case 'SEARCH_MY_MISSING_ARTICLE':
                handler(button);
                navigate(
                    `assign-article?articleId=${button.params.ARTICLE_ID}`,
                    undefined,
                    getContext ? getContext() : undefined,
                );
                break;
            case 'NOTIFY_AUTHOR_SUPPORT':
                handler(button);
                navigate(
                    `assign-article?journalId=${button.params.JOURNAL_ID}`,
                    undefined,
                    getContext ? getContext() : undefined,
                );
                break;
            case 'FIND_ARTICLE':
                handler(button);
                navigate(
                    `advancedsearch?jid=${button.params.JOURNAL_ID}&type=article`,
                    undefined,
                    getContext ? getContext() : undefined,
                );
                break;
            case 'VIEW_ON_DASHBOARD':
                handler(button);
                navigate(`dashboard?focusOnArticle=${button.params.ARTICLE_ID}`);
                break;
            default:
                break;
        }
    } else {
        if (button?.code === 'CREATE_OA_ORDER' && button.additionalInfo) {
            sendAnalytics(button.additionalInfo)();
        }
        handler(button);
    }
});

export {
    ActionButton,
    asDate,
    getActionsButtons,
    getStatusDescr,
    getStatusShort,
    handleClick,
    viewFullArticle,
    viewShareableLink,
};
