import get from 'lodash.get';
import { enrichPrices, sortAsc } from 'app/blocks/common/getCurrencySymbol';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import routes from 'app/pages/routes';
import { mapOrderToDefValues } from './middleware';
import { REST } from './utils';

const { GET, getRestUrl } = REST('/licenseSigning');
const { GET: GET2, POST: POST2 } = REST('/articles');

let combinedFlowCache = null;
let combinedFlowCacheTimeout = null;

export type CopyrightOwnership = {
    id: string;
    name: string;
    subItems: Array<CopyrightOwnership>;
    subCategoryTypes: Array<{
        shortName: string;
        longName: string;
        isSubscription: boolean;
    }>;
};

type CombinedFlowStep = {
    confirmed: boolean;
    show: boolean;
};

export interface CombinedFlowPayload {
    confirmAuthors: CombinedFlowStep & {
        rcaConfirmationData: Array<{
            firstName: string;
            lastName: string;
            role: ParticipantRole;
            participantId: string;
            email: string;
        }>;
        authorAffiliationResponses: Array<{
            participantId: string;
            institutions: string[];
        }>;
    };
    researchFunders: CombinedFlowStep & {
        items: Array<{
            grants: {
                authors: Array<{
                    email: string;
                    firstName: string;
                    lastName: string;
                    middleName: string;
                    name: string;
                    participantId: string;
                    prefix: string;
                    role: ParticipantRole;
                    suffix: string;
                }>;
                grantNumber: string;
            };
            researchFunder: {
                custom: boolean;
                doi?: string;
                id: string;
                name: string;
            };
        }>;
        latestChangeByUser: boolean;
    };
    articleAffiliations: CombinedFlowStep & {
        cancelableAuthorPaidOrderExists: boolean;
        sanctionAffiliationsAndSubsLicenseExists: boolean;
        affiliations: Array<{
            affiliationId: string;
            city: string;
            countryCode: string;
            countryName: string;
            custom: boolean;
            institutionId: string;
            institutionName: string;
            postalCode: string;
            stateCode: string;
            startDate: number;
            endDate?: number;
            submissionData: boolean;
        }>;
    };
    copyrightOwnership: CombinedFlowStep & {
        copyrightOwnerships: Array<{
            copyrightOwnership: string;
            copyrightOwnershipSubtype: string;
        }>;
        singleAuthor: string;
    };
    openAccessOption: CombinedFlowStep & {
        authorSelectedOnlineOpen: boolean;
        enableSubscription: boolean;
        eligibility: {
            defaultOptInForOo: boolean;
            eligibilityMessage: string;
            eligibleWoaFunder: {
                id: string;
                name: string;
            };
            orderConfirmationMessage: string;
        };
        isInstitutionalDiscount?: boolean;
        orderType: 'ONLINE_OPEN_AUTHOR_PAID' | 'ONLINE_OPEN_FUNDER_PAID';
        readOnly: boolean;
    };
    licenseAgreementTypes: CombinedFlowStep & {
        licenseChoices: Array<{
            licenseChoice: Array<{ value: string }>;
            licenseType: { value: string };
        }>;
    };
    onlineOpenPayment: CombinedFlowStep & {
        canChangePaymentMethod: boolean;
        canDeleteDraft: boolean;
        isEditOrderFlow: boolean;
        onlineOpenOrder: Object;
    };
    licenseSubmission: CombinedFlowStep & {
        electronicLicenseSubmission: {
            authorSignature: string;
        };
        printLicenseSubmission: Array<{
            copyrightOwnership: string;
            copyrightOwnershipSubtype: string;
            files: Array<string>;
        }>;
        signingMethod: 'ELECTRONIC' | 'PRINT';
        stepTracker: {
            signingLicenseStep: {
                confirmed: boolean;
            };
            obtainOrDownloadLicenseStep: {
                confirmed: boolean;
            };
            signingInstructionsStep: {
                confirmed: boolean;
            };
        };
    };
    confirmationPage: CombinedFlowStep & {
        isAuthorPaidOrder?: boolean;
        page: 'COMBINED_CONFIRMATION' | 'LICENSE_CONFIRMATION' | 'OAORDER_CONFIRMATION' | 'OOORDER_CONFIRMATION';
        rcaEmail?: string;
        rcaName?: string;
        woadMessage?: string;
        coverWidget: {
            publishedStatus: boolean;
            coverCharge: boolean;
        };
    };
}

export const checkResearchFunderDeletionEligibility = (articleId, funderId): Promise<void> =>
    GET('/checkResearchFunderDeletionEligibility', {
        articleId,
        funderId,
    });

export const listCopyrightOwnerships = (): Promise<CopyrightOwnership[]> => GET('/listCopyrightOwnerships');

export const electronicLicenseText = (articleId: string): Promise<string> =>
    GET('/getElectronicLicenseText', { articleId });

export const getPrintLicensePdfUrl = (articleId, copyrightOwnership, copyrightOwnershipSubtype): string =>
    `${getRestUrl()}/getPrintLicensePdfTemplate?articleId=${articleId}&copyrightOwnership=${copyrightOwnership}${
        copyrightOwnershipSubtype ? `&copyrightOwnershipSubtype=${copyrightOwnershipSubtype}` : ''
    }`;

export async function getAll(articleId: string): Promise<CombinedFlowPayload> {
    let result;

    if (combinedFlowCache) {
        result = combinedFlowCache;
        combinedFlowCache = null;
    } else {
        result = await GET2(`/${articleId}/combinedFlow`);
    }

    if (get(result, `${StepEnum.PAYMENT}.onlineOpenOrder`)) {
        result[StepEnum.PAYMENT].onlineOpenOrder = mapOrderToDefValues(result[StepEnum.PAYMENT].onlineOpenOrder);
    }
    return result;
}

export async function initFlow(articleId: string, action: string): Promise<void> {
    combinedFlowCache = await POST2(`/${articleId}/combinedFlow`, { action });

    clearTimeout(combinedFlowCacheTimeout);
    combinedFlowCacheTimeout = setTimeout(() => {
        combinedFlowCache = null;
    }, 5000);
}

export async function startFlow(
    articleId: string,
    action: string,
    openSection?: string,
    successCallback = () => {},
): Promise<void> {
    await initFlow(articleId, action);
    successCallback();

    if (openSection) {
        routes.navigateToUrl(`/license-signing/${articleId}/${openSection}`);
    } else {
        routes.navigateToUrl(`/license-signing/${articleId}`);
    }
}

export async function confirmStep(articleId: string, step: string, data: Object): Promise<CombinedFlowPayload> {
    const result = await POST2(`/${articleId}/combinedFlow/${step}`, data);

    if (get(result, `${StepEnum.PAYMENT}.onlineOpenOrder`)) {
        result[StepEnum.PAYMENT].onlineOpenOrder = mapOrderToDefValues(result[StepEnum.PAYMENT].onlineOpenOrder);
    }
    return result;
}

export const confirmIncorrectAuthorList = (articleId, reasons, message): Promise<CombinedFlowPayload> =>
    POST2(`/${articleId}/combinedFlow/confirmAuthors/listIsIncorrect`, { reasons, message });

export const deleteOnlineOpenOrderDraft = (articleId: string): Promise<CombinedFlowPayload> =>
    POST2(`/${articleId}/combinedFlow/onlineOpenPayment/deleteOrderDraft`);

export const comfirmLicenseMultiStep = (articleId: string, selectionType: string): Promise<void> =>
    POST2(`/${articleId}/combinedFlow/stepTracker`, { selectionType });

export async function getBasePrices(articleId: string): Promise<
    Array<{
        amount: number;
        base?: number;
        currency: string;
        name: string;
        symbol: string;
    }>
> {
    const prices = await GET2(`/${articleId}/combinedFlow/prices`);

    return enrichPrices(prices).sort(sortAsc);
}
