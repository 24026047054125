const PageEnum = {
    LOGIN: 'Login',
    REGISTER: 'Register',
    RECOVER: 'Password Recovery',
    DASHBOARD: 'My Dashboard',
    PROFILE: 'Profile',
    COMMUNICATION_HISTORY: 'All Communication History',
    COMMUNICATION_HISTOTY_ITEM: 'Communication History Notification',
    ORDERS: 'My Orders',
    COLLEAGE_INVITATIONS: 'Colleague Invitations',
    SEARCH: 'Search Results',
    ADVANCED_SEARCH: 'Advanced Search',
    ARTICLE_SEARCH_RESULTS: 'Article Search Results',
    JOURNAL_SEARCH_RESULTS: 'Journal Search Results',
    ARTICLE_ASSIGNMENT: 'Article Assignment',
    FUNDERS: 'Funders',
    GRANT_DETAILS: 'Grant Details',
    AFFILIATIONS: 'Affiliations',
    AFFILIATIONS_SELECT_WOA_INSTITUTION: 'Select WOA Institution',
    AFFILIATIONS_SELECT_PRIMARY: 'Select Primary Affiliation',
    COPYRIGHT_OWNERSHIP: 'Copyright Ownership',
    COPYRIGHT_OWNERSHIP_US_GOVERMENT: 'US Government Ownership',
    COPYRIGHT_OWNERSHIP_SINGLE_AUTHOR: 'Single Author',
    OPEN_ACCESS_OPTIONS: 'Open Access Options',
    LICENSE_AGREEMENT_TYPES: 'License Agreement Types',
    ONLINE_OPEN_ORDER_INITIATE: 'OnlineOpen Order: Initiate',
    ONLINE_OPEN_ORDER_REVIEW: 'OnlineOpen Order: Review',
    OPEN_ACCESS_ORDER_INITIATE: 'Open Access Payment: Initiate',
    OPEN_ACCESS: 'Open Access Payment: ',
    LICENSE_SUBMISSION_ELECTRONIC: 'Electronic License Submission',
    LICENSE_SUBMISSION_PRINT: 'Print License Submission',
    CONFIRMATION_ONLINE_OPEN_ORDER_AND_LICENSE_SUBMITTED: 'Confirmation: OnlineOpen Order and License Submitted',
    CONFIRMATION_LICENSE_SUBMITTED: 'Confirmation: License Submitted',
    CONFIRMATION_OPEN_ACCESS_ORDER_SUBMITTED: 'Confirmation: Open Access Order Submitted',
    CONFIRMATION_ONLINE_OPEN_ORDER_SUBMITTED: 'Confirmation: OnlineOpen Order Submitted',
    EMAIL_VERIFICATION: 'Email Verification',
    TERMS_OF_SERVICE: 'Terms Of Service',
    HOME: 'Home',
    PUB_EDITOR: 'License Verification',
    VIEW_ALL_ORDERS: 'Order History',
    ARTICLE_PAGE: 'Manage this article',
} as const;

export default PageEnum;
