import { keymirror } from 'app/blocks/common/utils';

const LicenseFlowEnum = keymirror({
    CREATE_OO_ORDER: '',
    CONFIRM_OO_ORDER: '',
    SUBMIT_OO_ORDER: '',
    EDIT_OO_ORDER: '',
    CREATE_OO_ORDER_VCH: '',
    COMPLETE_REQUIRED_STEPS_VCH: '',

    CANCEL_OO_ORDER: '',
    CANCEL_OO_DRAFT: '',

    SIGN_LICENSE: '',
    EDIT_LICENSE: '',
    UPLOAD_LICENSE: '',
    DOWNLOAD_LICENSE: '',

    CHANGE_AFFILIATION_DATA: '',
});

export default LicenseFlowEnum;
