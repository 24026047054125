import cn from 'classnames';
import React, { Component } from 'react';
import listensToClickOutside from 'react-onclickoutside';
import { KeyCode, eventContainsKey } from 'app/blocks/common/key-utils';
import './drop-down.scss';

type Props = {
    className?: string;
    items?: Array<{
        code: string;
        title: string;
        url: string;
    }>;
    preventToggle?: boolean;
    title: string;
    seleniumid: string;
};

class DropDown extends Component<Props, { isOpen: boolean }> {
    static defaultProps = {
        className: '',
        items: [],
        preventToggle: false,
    };

    state = {
        isOpen: false,
    };

    onKeyDown = e => {
        const { isOpen } = this.state;
        if (eventContainsKey(e, KeyCode.ENTER)) {
            this.toggleMenu();
        } else if (eventContainsKey(e, KeyCode.ESC) && isOpen) {
            this.closeMenu();
        }
    };

    toggleWithPreventCheck = () => {
        if (this.props.preventToggle) {
            this.setState({ isOpen: true });
        } else {
            this.toggleMenu();
        }
    };

    closeMenu = () => this.setState({ isOpen: false });

    toggleMenu = () => this.setState(state => ({ isOpen: !state.isOpen }));

    handleClickInsideDropdown = e => {
        if (e.target.tagName === 'A') {
            this.closeMenu();
        }
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    handleClickOutside = () => this.closeMenu();

    render() {
        const { className, items, seleniumid, title } = this.props;

        return (
            <div className="drop-down" data-seleniumid={seleniumid}>
                <a
                    className={className}
                    onClick={this.toggleWithPreventCheck}
                    onKeyDown={this.onKeyDown}
                    role="button"
                    tabIndex={0}
                >
                    {title}
                    <span className="p-l_micro">
                        <span className="caret" />
                    </span>
                </a>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <ul
                    className={cn('dropdown-menu', { 'drop-down_open': this.state.isOpen })}
                    onClick={this.handleClickInsideDropdown}
                >
                    {items.map(item => (
                        <li key={item.code}>
                            <a data-seleniumid={`${item.code}-dropdown-item`} href={item.url}>
                                {item.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default listensToClickOutside(DropDown);
