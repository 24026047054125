import emitter from 'app/blocks/common/events/emitter';
import Events from 'app/blocks/common/events/Events';
import storage from 'app/blocks/common/storage';
import { isNonProd } from 'app/blocks/middleware/config';
import { appendInvitationTokenToParams, removeInvitationToken } from './register';
import { GET, POST } from './utils';

let user: User = { loggedIn: false };

// export only for testing purpose
export function setAuthenticated(value: boolean) {
    if (storage.get('authenticated') === value) {
        return;
    }

    storage.set('authenticated', value);
}

export function getUser(): User {
    return user;
}

export async function refreshUser(): Promise<User> {
    user = await GET('/v2/loggedUserData');
    emitter.emit(Events.USER_CHANGED, user);
    setAuthenticated(user.loggedIn);

    return user;
}

export async function login(originParams) {
    const result = await POST('/authenticate', appendInvitationTokenToParams(originParams));
    removeInvitationToken();
    await refreshUser();

    return result;
}

export async function logout(stayOnPage = false) {
    try {
        await GET('/v2/logout');
    } finally {
        user = { loggedIn: false };
        setAuthenticated(false);

        if (stayOnPage) {
            emitter.emit(Events.USER_CHANGED, { loggedIn: false });
        } else {
            window.location.href = !isNonProd()
                ? `https://wiley.scienceconnect.io/api/oidc/logout?post_logout_redirect_uri=${window.location.origin}`
                : `https://wileyas-stag.connectscience.io/api/oidc/logout?post_logout_redirect_uri=${window.location.origin}`;
        }
    }
}

async function getConnectUrl() {
    return await GET('/connect/url');
}

export async function authenticateASLogin(originParams) {
    const result = await POST('/authenticate/connect', appendInvitationTokenToParams(originParams));
    removeInvitationToken();
    await refreshUser();

    return result;
}

export async function getUiConnectUrl() {
    const silentAuthUrl = await getConnectUrl();
    const replacedUrl = silentAuthUrl?.replace('&prompt=none', '');
    return replacedUrl;
}

storage.watch('authenticated', newValue => {
    setTimeout(() => {
        if (user?.loggedIn && !newValue) {
            logout(!window.location.href.includes('/index.html#'));
        } else {
            refreshUser();
        }
    }, 0);
});
